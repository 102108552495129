import React from 'react';
import './App.css';

const photos = [
  "01.jpeg",
  "02.jpeg",
  "03.jpeg",
  "04.jpeg",
  "05.jpeg",
  "06.jpeg",
  "07.jpeg",
  "08.jpeg",
  "09.jpeg",
  "010.jpeg",
  "011.jpeg",
  "012.jpeg",
  "013.jpeg",
  "014.jpeg",
  "015.jpeg",
  "016.jpeg",
  "017.jpeg",
  "018.jpeg",
  "019.jpeg"
];

const App = () => {
  return (
      <div className="photo-container">
        {photos.map((photo, index) => (
            <img key={index} src={photo} alt={`Photo ${index + 1}`} className="photo" />
        ))}
      </div>
  );
}

export default App;
